<template>
    <b-card @click="$emit('click')" no-body :bg-variant="isActive ? '' : 'transparent' " :class=" isActive ? 'is-picker m-0' : 'is-picker with-border' ">
        <b-card-body style="padding: 24px;">
            <p style="margin-bottom: 6px; line-height: 20px;">{{$t('wallet.total-balance')}}</p>
            <p style="margin-bottom: 6px; font-size: 28px; font-weight: 600; line-height: 32px;">{{ balance.toFixed(7) }} ₿</p>
            <span class="text-muted">≈ {{ currency_balance }} {{ symbol }}</span>
            <!--<transition name="fade-top-2x">-->
                <b-row v-if="isActive" style="margin-top: 20px;">
                    <b-col cols="6" style="padding-left: 5px; padding-right: 5px;">
                        <b-button @click="showModal('input')" variant="primary" block style="font-weight: 400; font-size: 14px; border-radius: 6px;">{{$t('wallet.deposit')}}</b-button>
                    </b-col>
                    <b-col cols="6" style="padding-left: 5px; padding-right: 5px;">
                        <b-button @click="showModal('output')" variant="gray" block style="font-weight: 400; font-size: 14px; border-radius: 6px;">{{$t('wallet.withdraw')}}</b-button>
                    </b-col>
                    <b-col cols="12" style="padding-left: 5px; padding-right: 5px;">
                        <b-button @click="showModal('transfer')" variant="gray" block style="font-weight: 400; font-size: 14px; border-radius: 6px; margin-top: 12px;">{{$t('wallet.transfer')}}</b-button>
                    </b-col>
                </b-row>
            <!--</transition>-->
        </b-card-body>
    </b-card>
</template>

<script>

    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"
    
    export default {

        data() {
            return {}
        },
        props: {
            isActive: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            showModal( action ) {
                this.$emit("showModal", action );
            }
        },
        components: {

        },
        computed: {
            
            symbol() {
                return User.getSymbol()
            },
            
            user() {
                return User.self;
            },
            
            balance() {
                return (this.user.balance + this.user.wallet.balance);
            },
            
            currency_balance() {
                return (Blockchain.tickers.values[User.currency].last * this.balance).formatMoney(2," "," ");
            }
            
        },
        watch: {

        },
        mounted() {

        }

    }

</script>