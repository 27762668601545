<template>
    <div>
        
        <div >
            <p class="text-muted" style="font-size: 13px; line-height: 20px;">{{$t('wallet.modals.deposit.notif-1')}}</p>
            <p class="text-muted" style="font-size: 13px; line-height: 20px;">{{$t('wallet.modals.deposit.notif-2')}}</p>
            <p class="text-muted" style="font-size: 13px; line-height: 20px;">{{$t('wallet.modals.deposit.notif-3')}}</p>
        </div>
        
        <div class="data-form">
            <b-form-group>
                <span class="text-muted" style="font-size: 12px;">{{$t('wallet.to')}}</span>
                <p class="m-0">{{$t('wallet.main-wallet')}}</p>
            </b-form-group>
            <b-form-group>
                <div class="d-flex justify-content-between">
                    <div style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.deposit-amount')}}</span>
                        <b-form-input v-model="amount" placeholder="0,00" :disabled="!User.self.wallet.address" type="number" />
                    </div>
                    <div class="mt-auto mb-auto">
                        <b-dropdown
                            :text="ticker"
                            variant="muted"
                            size="sm"
                            right
                            no-caret
                          >
                            
                            <template #button-content>
                                <div class="d-flex">
                                    <span>{{ ticker }}</span>
                                    <feather-icon icon="ChevronDownIcon" style="margin: auto 2px;" />
                                </div>
                            </template>

                            <!-- <b-dropdown-item @click="changeCurrencyType(User.currency)">{{ User.currency }}</b-dropdown-item> -->
                            <b-dropdown-item @click="changeCurrencyType('BTC')">BTC</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </b-form-group>
        </div>
        
        <div v-if="User.self.wallet.address" class="d-flex">
            
            <div style="margin-right: 26px;">
                <b-form-group class="form-group-with-label">
                    <div class="d-flex justify-content-between"> 
                        <div ref="container" class="wordwrap">
                            <span class="text-muted" style="font-size: 12px;">{{$t('wallet.address')}}</span>
                            <p style="font-size: 16px; font-weight: 500; margin: 0; word-break: break-all;">{{ User.self.wallet.address }}</p>
                        </div>
                        <div v-ripple @click="copyBitcoinAddress" style="margin: auto 12px; margin-right: 0;">
                            <feather-icon icon="CopyIcon" size="20" class="text-muted cursor-pointer" />
                        </div>
                    </div>
                </b-form-group>
            </div>
            
            <div>
                <qrcode :value="qr_code_value" :options="{ width: 80, margin: 0 }"></qrcode>
            </div>
            
        </div>

        <div v-else class="d-flex">
            <p> <span>{{$t('wallet.first-generate')}}</span> <a style="color: rgb(66, 111, 246); text-transform: lowercase" @click="openBasicWallet">{{$t('wallet.main-wallet')}}</a> </p>
        </div>
        
    </div>
</template>

<script>
    
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {

        data() {
            return {
                User,
                amount: null,
                ticker: 'BTC'
            }
        },
        methods: {

            openBasicWallet() {
                this.$emit('updateSelectedWalletIndex', 2);
                this.$emit('close');
            },
            
            copyBitcoinAddress( args ) {
                
                let container = this.$refs.container;
                this.$copyText( this.User.self.wallet.address, container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t('toasts.titles.copy'),
                        text: this.$t('toasts.text.btc-address'),
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                    }
                });  
            },
            
            changeCurrencyType( ticker ) {
                
                if( ticker === this.ticker ) return;
                
                if( this.amount ) {
                    switch (ticker) {
                        case "BTC" :  this.amount =  this.amount / Blockchain.tickers.values[User.currency].last; break;
                        default:   this.amount = this.amount * Blockchain.tickers.values[User.currency].last; break;
                    }
                }
                
                this.ticker = ticker;
            }
        },
        computed: {
            
            amount_for_send() {
                
               if( this.amount ) {
                   
                   if( this.ticker === 'BTC' ) {
                       return this.amount;
                   }
                   
                   return this.amount / Blockchain.tickers.values[User.currency].last;
                   
               } 
               
               return null;
            },
            
            qr_code_value() {
                
                let result = `bitcoin:${User.self.wallet.address}`;
                
                if( this.amount_for_send ) {
                    result = result + `?amount=${this.amount_for_send}`;
                }
                
                return result;
                
            }
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>