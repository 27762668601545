<template>
    <div>
        <div style="padding-left: 16px;">
            <p style="font-weight: 600; font-size: 20px; margin-bottom: 16px;">{{$t('wallet.wallet')}}</p>
        </div>
        
        <div class="d-flex wallet-cards" style="overflow-x: scroll; padding-left: 16px; padding-right: 16px;">
            <swiper ref="swiper" :options="swiperOptions">
                <swiper-slide >
                    <general-wallet-view @showModal="showModal" @click="updateSelectedWalletIndex(0)" :isActive="isActive(0)" />
                </swiper-slide>
                <swiper-slide>
                    <calculated-wallet-view @showModal="showModal" @click="updateSelectedWalletIndex(1)" :isActive="isActive(1)" />
                </swiper-slide>
                <swiper-slide>
                    <basic-wallet-view @showModal="showModal" @click="updateSelectedWalletIndex(2)" :isActive="isActive(2)" :isCreated="isCreateBasicWallet"/>                    
                </swiper-slide>
            </swiper>
        </div>
        
        <div>
            <transactions-mobile-view ref="transactions" :isCreateBasicWallet="isCreateBasicWallet" @createWallet="create"/>
        </div>
        
        <b-modal id="input-modal" v-model="modalsVisibility.input" :title="$t('wallet.depositing-funds')" centered hide-footer>
            <input-modal-content @updateSelectedWalletIndex="updateSelectedWalletIndex" @close="closeModal" />
        </b-modal>
        
        <b-modal id="output-modal" v-model="modalsVisibility.output" :title="$t('wallet.withdraw-funds')" centered hide-footer >
            <output-modal-content @afterTransactionSend="afterTransactionSend" @updateSelectedWalletIndex="updateSelectedWalletIndex" @close="closeModal" />
        </b-modal>
        
        <b-modal id="transfer-modal" v-model="modalsVisibility.transfer" :title="$t('wallet.transfer-with-qubity')" centered hide-footer >
            <transfer-modal-content @close="closeModal" @afterTransactionSend="afterTransactionSend" @updateSelectedWalletIndex="updateSelectedWalletIndex" />
        </b-modal>
        
        
    </div>
</template>

<script>

    import User from "@/modules/user/"

    import GeneralWalletView from "./widgets/general.desktop"
    import CalculatedWalletView from "./widgets/calculated.desktop"
    import BasicWalletView from "./widgets/basic.desktop"
    
    import TransactionsMobileView from "./transactions/transactions.mobile"
    
    import InputModalContent from "./modals/input"
    import OutputModalContent from "./modals/output"
    import TransferModalContent from "./modals/transfer"
    
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper' 
    import 'swiper/css/swiper.css'
    
    export default {

        data() {
            return {
                isCreateBasicWallet: false,
                wallet: null,
                selectedWalletIndex: 0,
                swiperOptions: {
                    init: false,
                    slidesPerView: 'auto',
                    centeredSlides: false,
                    spaceBetween: 0,
                    slidesOffsetBefore: 0,
                    autoHeight: true,
                    on: {
                        slideChange: ( swiper ) => {
                            this.updateSelectedWalletIndex( this.$refs.swiper.swiperInstance.activeIndex );
                        }
                    }
                },
                modalsVisibility: {
                    input: false,
                    output: false,
                    transfer: false
                }
                
            }
        },
        methods: {
            
            isActive( value ) {
                return this.selectedWalletIndex === value;
            },
            
            showModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },

            closeModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
            },
            
            
            afterTransactionSend( args ) {
                this.update();
            },
                
            updateSelectedWalletIndex( value  ) {
                this.selectedWalletIndex = value;
                this.$refs.transactions.changeWallet( value );
                this.$refs.swiper.swiperInstance.slideTo( value );
            },
            
            update() {
                
                this.$request.get("wallet.get").then( wallet => {
                    User.self.balance = wallet.contracts.balance;
                    User.self.wallet = wallet.general;
                });
                
            },
            
            get() {
                
                this.$request.get("wallet.get").then( wallet => {
                    User.self.balance = wallet.contracts.balance;
                    User.self.wallet = wallet.general;

                    if (wallet.general.address === null) this.isCreateBasicWallet = false
                    else this.isCreateBasicWallet = true

                    this.$refs.transactions.get();
                });
                
            },

            create(cb) {
                
                this.$request.post("wallet.create").then( wallet => {
                    User.self.wallet = wallet.general;
                    this.isCreateBasicWallet = true
                    this.is_loading_created = false;
                    if (cb) cb();
                }).catch(err => {
                    this.is_loading_created = false;
                    if (cb) cb(error);
                });

            }
            
            
        },
        components: {
            GeneralWalletView,
            CalculatedWalletView,
            BasicWalletView,
            
            TransactionsMobileView,
            
            InputModalContent,
            OutputModalContent,
            TransferModalContent,
            
            Swiper, 
            SwiperSlide
        },
        watch: {

        },
        mounted() {
            
            if( this.$route.query.act ) {
                this.showModal( this.$route.query.act );
            }
            
            this.get();
            
            setTimeout(() => {
                this.$refs.swiper.swiperInstance.init();
           }, 100 );
            
        }

    }

</script>