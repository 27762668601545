<template>
          
        <b-card no-body>
            <b-card-body v-if="isBasicWallet">
                <b-card-title>
                    {{$t('wallet.transactions')}}
                </b-card-title>
                <b-row>
                    
                    <b-col class="d-flex">
                        <b-form-group class="mt-auto mb-auto">
                            <b-button-group>
                                <template v-for="(item, index) in types">
                                    <b-button  @click="type = item.value" :variant="type === item.value ? 'active' : 'inactive' " size="sm" style="height: 36px;">{{ $t(item.label) }}</b-button>
                                    <div v-if="index < types.length - 1" class="delimiter"></div>
                                </template>
                            </b-button-group>                    
                        </b-form-group>
                    </b-col>
                    
                    <b-col class="d-flex">
                        <b-form-group class='mt-auto mb-auto ml-auto' style="padding: 6px 8px;">
                            <b-button-group>
                                <b-button @click=" ticker = User.currency " :variant="ticker != 'BTC' ? 'active' : 'inactive' " size="sm" style="height: 36px;">{{ User.currency }}</b-button>
                                <div class="delimiter"></div>
                                <b-button @click=" ticker = 'BTC' " :variant="ticker === 'BTC' ? 'active' : 'inactive' " size="sm" style="height: 36px;">BTC</b-button>
                            </b-button-group>
                        </b-form-group>  
                    </b-col>
                    
                </b-row>
                <b-row class="data-form" style="margin-top: 16px;">
                    <b-col>
                        <b-form-group class="pt-0 pb-0">
                            <div class="d-flex justify-content-between mt-auto mb-auto" style="height: 56px;">
                                <div class="mt-auto mb-auto" style="flex-grow: 1">
                                    <!--<div class="text-muted" style="font-size: 12px;">Период</div>-->
                                    <date-range-picker
                                        ref="picker"
                                        :locale-data="$i18n.locale == 'ru' ? calendar_locale : calendar_en"
                                        :max-date="maxPickerDate"
                                        :singleDatePicker="false"
                                        :timePicker="false"
                                        :timePicker24Hour="true"
                                        :showWeekNumbers="false"
                                        :showDropdowns="false"
                                        :autoApply="false"
                                        :linkedCalendars="false"
                                        :ranges="false"
                                        v-model="period"
                                        @update="get"
                                    >
                                        <template v-slot:input="picker" style="min-width: 350px;">
                                            <span v-if="picker.startDate" class="text-muted">{{ picker.startDate | date }} - {{ picker.endDate | date }}</span>
                                            <span v-else class="text-muted font-weight-normal">{{$t('wallet.period')}}</span>
                                        </template>

                                    </date-range-picker>
                                </div>

                                <feather-icon icon="CalendarIcon" class="mt-auto mb-auto text-muted" size="18"  />

                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group class="pt-0 pb-0">
                            <div class="d-flex justify-content-between mt-auto mb-auto" style="height: 56px;">
                                <div class="mt-auto mb-auto" style="flex-grow: 1">
                                    <v-select
                                      id="wallets-select"
                                      :options="wallets"
                                      v-model="wallet"
                                      label="label"
                                     :placeholder="$t('wallet.wallet')"
                                      :clearable="true"
                                      :filterable="false"
                                    >
                                         <template #selected-option="item">
                                             <span v-if="item.value" class="text-muted">{{ item.label }}</span>
                                             <span v-else class="text-muted">{{$t('wallet.wallet')}}</span>
                                        </template>
                                    </v-select>
                                </div>
                                <feather-icon @click='focusDropdown' icon="ChevronDownIcon" class="text-muted cursor-pointer" size="18" style="margin: auto 0px;" />
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>

            <b-card-body v-else>
                <b-card-title>
                    {{$t('wallet.wallet-not-created')}}
                </b-card-title>
                    <b-overlay
                        :show="is_loading_created"
                        blur="50"
                        variant='transparent'
                        spinner-variant="primary"
                        rounded="sm"
                    > 
                       <div class="d-flex" :style="is_loading_created ? 'opacity: 0.5' : ''">
                            <div  style="width: calc(100% - 220px);">
                                <div style="position: sticky; top: 16px;">
                                    <p>
                                        {{$t('wallet.generate-wallet-text-1')}}
                                    </p>
                                    <p>
                                        {{$t('wallet.generate-wallet-text-2')}}
                                    </p>
                                </div>
                            </div>
                            <div style="width: 220px;">
                                <div class="d-flex">
                                     <b-img src="~@/assets/images/site/wallet_i.png" fluid width="200px" />
                                </div>
                            </div>
                        </div>

                        <b-button @click="createBasicWallet" variant="primary" block style="width: 160px; font-weight: 400; font-size: 14px; border-radius: 6px;">Сгенерировать</b-button>

                    </b-overlay>

            </b-card-body>
            
            <div v-if="isBasicWallet">
                <b-overlay
                    :show="is_loading"
                    blur="50"
                    variant='transparent'
                    spinner-variant="primary"
                    rounded="sm"
                >  
                            
                    <b-card-body class='d-flex flex-column' v-if='transactions && transactions.items.length === 0' >
                        <div class="d-flex flex-column justify-content-center">
                            <div class="nodata-icon ml-auto mr-auto"></div>
                            <p class="text-muted mt-1 ml-auto mr-auto text-center">
                                {{$t('tables.no-data')}}
                            </p>
                        </div>
                    </b-card-body>
                    
                    <b-table-simple v-else-if="transactions" id="table-transactions" responsive  >

                        <b-thead>
                            <b-tr>
                                <b-th>{{$t('tables.date')}}</b-th>
                                <b-th>{{$t('tables.txid')}}</b-th>
                                <b-th> 
                                    <i18n path="tables.wallet-address" tag="span">
                                        <br>
                                    </i18n>
                                </b-th>
                                <b-th>{{$t('tables.sum')}}</b-th>
                            </b-tr>
                        </b-thead>

                        <b-tbody>
                            <b-tr v-for="(item, index) in transactions.items" :key="index">
                                <b-th class="text-black font-weight-normal">
                                    {{ item.date }}, 
                                    <span class="text-muted">{{ localTime(item.timestamp) }}</span>
                                </b-th>
                                <b-th class="text-black font-weight-normal">
                                    <b-link v-if="item.txid"  :href="`https://www.blockchain.com/btc/tx/${item.txid}`" target="_blank" class="text-black" v-b-tooltip.hover :title="item.txid" >{{ start_and_end(item.txid) }}</b-link><span v-else>–</span>
                                </b-th>
                                <b-th class="text-black font-weight-normal">
                                    <b-link v-if="item.address" :href="`https://www.blockchain.com/btc/address/${item.address}`" target="_blank" class="text-black" v-b-tooltip.hover :title="item.address" style="word-break: break-all;">{{ start_and_end(item.address,5,5) }}</b-link><span v-else>–</span>
                                </b-th>
                                <b-th class="text-black font-weight-normal text-right">
                                    <span v-if="item.out">
                                        {{ getValueByTicker((item.amount + item.fee)) }} {{ symbol }} 
                                    </span>
                                    <span v-else class="text-success">
                                        <span v-if="item.txid">{{ getValueWithSigned(getValueByTicker(item.amount)) }}</span>
                                        <span v-else>{{ getValueWithSigned(getValueByTickerFixed(item.amount)) }}</span> {{ symbol }}
                                    </span>
                                    <br>
                                    <span v-if="item.confirmations > 0" class="text-muted">
                                        {{$t('wallet.transaction-complete')}}
                                    </span>
                                    <span v-else class="text-primary" >
                                       {{$t('wallet.transaction-processing')}}
                                    </span>
                                    
                                </b-th> 
                                
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    
                    <b-skeleton-table v-else-if='is_loading' animation="fade" :columns='4' :rows='5'>
                
                    </b-skeleton-table> 
                    
                </b-overlay>
            </div>
        </b-card>
</template>

<script>
    
    import User from "@/modules/user/"
    import vSelect from 'vue-select'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import moment from 'moment'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    export default {
        props: {
            isCreateBasicWallet: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                
                User,
                
                ticker: "BTC",
                
                maxPickerDate: new Date(),
                
                calendar_locale: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    weekLabel: 'Н',
                    customRangeLabel: 'Период',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авн', 'Сен', 'Окт', 'Нов', 'Дек'],
                    firstDay: 0
                },
                
                transactions: null,
                
                is_loading: true,
                is_loading_created: false,
                
                wallet:  { label: this.$i18n.locale == 'ru' ? "Все" : "All", value: null },
                type: null,
                period:{
                    startDate: null,
                    endDate: null
                },
                
                wallets: [
                    { label: this.$i18n.locale == 'ru' ? "Все" : "All", value: null },
                    { label: this.$i18n.locale == 'ru' ? "Расчетный кошелек" : "Wallet of payments", value: 2 },
                    { label: this.$i18n.locale == 'ru' ? "Основной кошелек" : "Main wallet", value: 1 },
                ],
                
                types: [
                    { value: null, label: "wallet.tabs.all" },
                    { value: "in", label : "wallet.tabs.in" },
                    { value: "out", label : "wallet.tabs.out" }
                ],
                
            }
        },
        methods: {

            localTime(timestamp) {
                return moment.utc(timestamp * 1000).local().format('HH:mm');
            },
            
             getValueByTickerFixed ( value ) {
                 
                 if( this.ticker === "BTC" ) {
                    return value.toFixed(8);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
                
                 
             },
            
             getValueByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return Math.round10(value, -10);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
                
            },
            
            changeWallet( value ) {
                this.wallet = this.wallets[ value ];                
            },
            
            focusDropdown( args ) {
                let input = document.querySelector("#wallets-select input");
                if( input ) {
                    input.focus();
                }                
            },
            
            start_and_end(str, start = 10, end = 5) {
                if (str.length > 20) {
                  return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },
            
            get() {
                if (!this.isBasicWallet) return;

                this.is_loading = true;
                this.$request.get("transactions.get", this.params ).then( result => {
                    this.transactions = result;
                    this.is_loading = false;
                }).catch(err => {
                    this.is_loading = false;
                });
            },
            
            handleScroll( e ) {            
                                
                if (!this.isBasicWallet) return;
                if(!this.transactions) return;
                if(!this.transactions.has_more_pages) return;
                if( this.is_loading ) return;
                
                let el = e.target;
               
                if( el.scrollTop + el.offsetHeight > ( el.scrollHeight - 100 ) ) {
                    
                  let params = this.params;
                  
                  params['page'] = this.transactions.current_page + 1;

                  params['offset_tx'] = 40 * this.transactions.current_page;
                  
                  this.is_loading = true;
                  
                  this.$request.get("transactions.get", params ).then( result => {
                       this.transactions.has_more_pages = result.has_more_pages;
                       this.transactions.current_page = result.current_page;
                       this.transactions.items.push( ...result.items );
                       this.is_loading = false;                       
                  });
                  
                }
                
            },

            createBasicWallet() {
                if (this.is_loading_created) return 
                this.is_loading_created = true

                this.$emit('createWallet', (error) => {
                        this.is_loading_created = false

                        if (error) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: this.$t('toasts.titles.error-create-wallet'),
                                text: this.$t('toasts.text.error-create-wallet'),
                                icon: 'CheckCircleIcon',
                                variant: 'error',
                                }
                            });
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: this.$t('toasts.titles.success-create-wallet'),
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                                }
                            });
                        }
                    });
            },

            getValueWithSigned(value) {
                if (parseFloat(value) >= 0) return "+" + value;
                
                return value;
            }

        },
        filters: {
            date(val) {
              return val ? new Intl.DateTimeFormat('ru', { day: 'numeric',  year: '2-digit', month: 'numeric' }).format(val) : ''
            }
        },
        components: {
            vSelect,
            DateRangePicker
        },
        computed: {
            
            symbol() {
                if( this.ticker === "BTC" ) return "₿";
                return User.getSymbol();
            },
            
            params() {
                
                let period = null;
                
                if( this.period.startDate && this.period.endDate ) {
                    period = {};
                    period['start'] = this.period.startDate  ;
                    period['end'] = this.period.endDate  ;
                }
                
                return {
                    wallet_type: this.wallet.value,
                    tx_category: this.type,
                    period: period
                }
            },

            isBasicWallet() {
                if (!this.isCreateBasicWallet && this.wallet.value == 1) return false
                else return true
            }
        },
        
        created() {
            document.getElementsByClassName('content-wrapper')[0].addEventListener("scroll", this.handleScroll );
        },
        
        destroyed() {
            document.getElementsByClassName('content-wrapper')[0].removeEventListener("scroll", this.handleScroll );
        },
        
        watch: {
            
            "period" : function() {
                this.get();
            },
            
            "wallet" : function() {
                this.get();
            },
            
            "type" : function() {
                this.get();
            }
            
        },
        mounted() {
            this.is_loading = true;
        }

    }

</script>

<style lang="scss">
    
    @import '@core/scss/vue/libs/vue-select.scss';
    
     .reportrange-text {
        background-color: transparent !important;
        border: none !important;
    }
    
    .vue-daterange-picker {
        flex-grow: 1;
        /*height: 28px !important;*/
    }
    
    [dir] .daterangepicker td.in-range {
        background-color: #E4EAFD !important;
    }
    
    .dark-layout .daterangepicker td.in-range {
        background-color: #262642 !important;
        color: #FFF;
    }
    
    [dir] .daterangepicker td.active, [dir] .daterangepicker td.active:hover {
        background-color: #426FF6 !important;
    }
    
    [dir=ltr] .daterangepicker td.start-date {
        border-radius: 22px 0 0 22px;
    }
    
    [dir=ltr] .daterangepicker td.end-date {
        border-radius: 0 22px 22px 0;
    }
    
    [dir] .daterangepicker {
        border: none;
        border-radius: 16px;
        box-shadow: 0px 6px 40px rgba(99, 109, 137, 0.15);
    }
    
    .reportrange-text {
        margin: 0 !important;
        padding: 0 !important;
    }
    
    [dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::before, [dir=ltr] .input-group-lg .custom-checkbox .custom-control-label::before {
        left: 0px;
    }
    
    [dir=ltr] .custom-checkbox.b-custom-control-lg .custom-control-label::after, [dir=ltr] .input-group-lg .custom-checkbox .custom-control-label::after {
        left: -2px;
    }
    
    .dark-layout .daterangepicker .drp-buttons {
            border-top: 1px solid rgba(255,255,255,0.1);
    }
    
    .dark-layout .daterangepicker .calendar-table .next span, .dark-layout .daterangepicker .calendar-table .prev span {
        border-color: #fff;    
    }
    
</style>
 