<template>

    <b-overlay
        :show="is_loading"
        blur="50"
        variant='white'
        spinner-variant="primary"
        spinner-lg
        rounded="lg"
    > 
        <transition name="zoom-fade" mode="out-in">
            <div v-if="tx">
                <div class="d-flex">
                    <Lottie 
                        :options="animationData" 
                        :height="100" 
                        :width="200" 
                    />
                </div>

                <div class="d-flex justify-content-around">
                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.from')}}</span>
                        <p class="m-0">{{ wallet.label }}</p>
                    </b-form-group>

                    <div class="icon-gray-bg mt-auto mb-auto d-flex" style="width: 18px; height: 18px; border-radius: 18px; margin-left: 12px; margin-right: 12px;">
                        <feather-icon icon="ChevronRightIcon" class="text-muted m-auto" />
                    </div>

                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.to')}}</span>
                        <p class="m-0"><b-link class="text-black">{{ start_and_end(tx.address) }}</b-link></p>
                    </b-form-group>
                </div>

                <b-list-group flush class="no-hover" style="margin-top: 16px;">
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.transaction-number')}}
                        </span>
                        <span>
                            <b-link class="text-black">{{ start_and_end( tx.txid ) }}</b-link>
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.transfer-amount')}}
                        </span>
                        <span class="text-black ">
                            {{ tx.amount }} BTC
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.commission-amount')}}
                        </span>
                        <span class="text-black ">
                            {{ Math.abs(tx.fee) }} BTC
                        </span>
                    </b-list-group-item>

                </b-list-group>
                
                <b-row>
                    
                    <b-col>
                        <b-button @click="modalClose" variant="gray" block size="lg" style="border-radius: 16px; margin-top: 22px;">
                            {{$t('wallet.view-history')}}
                        </b-button>
                    </b-col>
                </b-row>

            </div>

            <div v-else-if="wr">
                <div class="d-flex">
                    <Lottie 
                        :options="animationData" 
                        :height="100" 
                        :width="200" 
                    />
                </div>

                <div class="d-flex" style="flex-direction: column;">
                    <p>{{$t('wallet.request-successfully')}}</p>
                    <p>{{$t('wallet.processing-time')}}</p>
                </div>

                <div class="d-flex justify-content-around">
                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.from')}}</span>
                        <p class="m-0">{{ wallet.label }}</p>
                    </b-form-group>

                    <div class="icon-gray-bg mt-auto mb-auto d-flex" style="width: 18px; height: 18px; border-radius: 18px; margin-left: 12px; margin-right: 12px;">
                        <feather-icon icon="ChevronRightIcon" class="text-muted m-auto" />
                    </div>

                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;"> {{$t('wallet.to')}}</span>
                        <p class="m-0"><b-link class="text-black">{{ start_and_end(wr.address) }}</b-link></p>
                    </b-form-group>
                </div>

                <b-list-group flush class="no-hover" style="margin-top: 16px;">
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.transfer-amount')}}
                        </span>
                        <span class="text-black ">
                            {{ wr.amount }} BTC
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.commission-amount')}}
                        </span>
                        <span class="text-black ">
                            {{ Math.abs(wr.fee) }} BTC
                        </span>
                    </b-list-group-item>

                </b-list-group>
                
                <b-row>
                    <b-col>
                        <b-button @click="modalClose" variant="gray" block size="lg" style="border-radius: 16px; margin-top: 22px;">
                            {{$t('wallet.view-history')}}
                        </b-button>
                    </b-col>
                </b-row>

            </div>

            <div v-else class="data-form">
                <b-form-group>
                    <div class="d-flex justify-content-between">
                        <div style="flex-grow: 1">
                            <span class="text-muted" style="font-size: 12px;">{{$t('wallet.from')}}</span>
                            <v-select
                              id="output-select"
                              :options="wallets"
                              v-model="wallet"
                              label="label"
                              :clearable="false"
                              :filterable="false"
                            />     
                        </div>
                        <feather-icon @click="focusDropdown" icon="ChevronDownIcon" class="text-muted cursor-pointer" size="18" style="margin: auto 2px;" />
                    </div>            
                </b-form-group>
                <b-form-group>
                    <span class="text-muted" style="font-size: 12px;">{{$t('wallet.address')}}</span>
                    <b-form-input v-model="address" :disabled="wallet.value == 1 && !user.wallet.address" :placeholder="user.wallet.address" />
                </b-form-group>
                <b-form-group>
                    <div class="d-flex justify-content-between">
                        <div style="flex-grow: 1;">
                            <span class="text-muted" style="font-size: 12px;">{{$t('wallet.withdrawal-amount')}}</span>
                            <b-form-input v-model="amount" :disabled="wallet.value == 1 && !user.wallet.address" placeholder="0,00" type="number" />
                        </div>
                        <div class="mt-auto mb-auto">
                            <b-dropdown
                                :text="ticker"
                                variant="muted"
                                size="sm"
                                right
                                no-caret
                              >

                                <template #button-content>
                                    <div class="d-flex">
                                        <span class="text-black mb-auto mb-auto">{{ ticker }}</span>
                                        <feather-icon icon="ChevronDownIcon" style="margin: auto 2px;" />
                                    </div>
                                </template>

                                <!-- <b-dropdown-item @click="changeCurrencyType(currency)">{{ currency }}</b-dropdown-item> -->
                                <b-dropdown-item @click="changeCurrencyType('BTC')">BTC</b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </b-form-group>

                <div class="d-flex justify-content-between" style="font-size: 12px;">
                    <span class="text-muted">
                        {{$t('wallet.available-amount')}}: {{ available_currency_balance }} {{ ticker }}
                    </span>
                    <b-link @click="outputAllAvailableBalance">
                        {{$t('wallet.withdraw-all-funds')}}
                    </b-link>
                </div>

                <div class="d-flex justify-content-between" style="font-size: 12px; margin-top: 8px;">
                    <span class="text-muted">
                        {{$t('wallet.priority')}}
                    </span>
                    <b-dropdown
                        variant="muted"
                        size="sm"
                        right
                        no-caret
                        style="padding: 0"
                      >

                        <template #button-content>
                            <div class="d-flex">
                                <span class="text-primary mb-auto mb-auto font-weight-normal" style="font-size: 12px;">{{ $t(priority.label) }}</span>
                                <feather-icon icon="ChevronDownIcon" class="text-primary" style="margin: auto 2px; margin-right: 0;" />
                            </div>
                        </template>

                        <b-dropdown-item v-for="(item, index) in priorities" :key="index" :active="item.value ===  priority.value" @click="priority = item">{{ $t(item.label) }}</b-dropdown-item>

                    </b-dropdown>
                </div>

                <hr>

                <div v-if="fee" class="d-flex justify-content-between" style=" margin-bottom: 6px;">
                    <span>{{$t('wallet.transaction-fee')}}</span>
                    <span>{{ fee_currency }} {{ ticker }}</span>
                </div>

                <div v-if="fee" class="d-flex justify-content-between" style=" margin-bottom: 6px;">
                    <span>{{$t('wallet.total-amount')}}</span>
                    <span>{{ total_amount }} {{ ticker }}</span>
                </div>

                <b-alert :show="!isAmountAvailable && amount && isAddressValide && fee && sign" variant="danger" class="font-weight-normal" style="padding: 14px; margin-top: 16px; font-size: 12px;">
                   {{$t('wallet.not-enough-funds')}}
                    <br> <span v-if="is_pay_contract">{{$t('wallet.possible-pay-contract')}}</span>
                    <br> <strong>{{$t('wallet.recommend-reducing-amount')}}</strong>
                </b-alert>
                <!-- <b-alert :show="!isAmountAvailable && amount && isAddressValide && fee && sign && is_pay_contract" variant="danger" class="font-weight-normal" style="padding: 14px; margin-top: 16px; font-size: 12px;">
                    Оплатить контракт возможно частями с разных счетов.
                </b-alert> -->
                <b-alert :show="error" variant="danger" class="font-weight-normal" style="padding: 14px; margin-top: 16px; font-size: 12px;">
                    {{ error_msg }}
                </b-alert>

                <div v-if="wallet.value == 1 && !user.wallet.address" class="d-flex">
                    <p> <span>{{$t('wallet.first-generate')}}</span> <a style="color: rgb(66, 111, 246); text-transform: lowercase" @click="openBasicWallet">{{$t('wallet.main-wallet')}}</a> </p>
                </div>

                <b-button @click="output" :disabled="!isCanSend" variant="primary" block size="lg" style="border-radius: 16px; margin-top: 22px;">

                    <div v-if="is_loading" class="d-flex justify-content-center">
                        <span class="mt-auto mb-auto ml-1">{{$t('wallet.btns.sending')}}</span>
                    </div>
                    <span v-else>
                        {{$t('wallet.btns.withdrawal')}}
                    </span>

                </b-button>

            </div>
        </transition>
    </b-overlay>
    
</template>

<script>
    
    import vSelect from 'vue-select'
    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain/"

    var Validator = require('wallet-address-validator');

    import * as SuccessAnimeView from "@/assets/anime/success.json"


    export default {

        data() {
            return {
                User,
                is_output_all: false,
                
                address: null,
                ticker: "BTC",
                amount: null,
                fee: null,
                sign: null,
                is_loading: false,
                is_disable_send: false,
                
                wallet: { label: this.$i18n.locale == 'ru' ? "Основной кошелек" : "Main wallet" , value: 1 },
                priority: { label: "wallet.medium", value: "medium" },
                
                priorities: [
                    { label: "wallet.low", value: "low" },
                    { label: "wallet.medium", value: "medium" },
                    { label: "wallet.high", value: "height" },
                ],
                
                wallets: [
                    { label: this.$i18n.locale == 'ru' ? "Основной кошелек" : "Main wallet", value: 1 },
                    { label: this.$i18n.locale == 'ru' ? "Расчетный кошелек" : "Wallet of payments", value: 2 }
                ],
                
                tx: null,
                wr: null,
                
                animationData: { 
                    animationData: SuccessAnimeView.default,
                    loop: false
                }, 
                
                error_msg: "",
                error: false,

                is_pay_contract: false
            }
        },
        methods: {            
            
            openBasicWallet() {
                this.$emit('updateSelectedWalletIndex', 2);
                this.$emit('close');
            },

            createrawtransaction() {
                if( !this.isAddressValide || !this.amount ) return;                
                                
                this.$request.post( "wallet.createrawtransaction", this.params ).then( result => {
                    this.fee = result.fee;
                    this.sign = result.sign;
                                        
                });
                
            },
            
            modalClose() {
                this.$emit("close", 'output' );
            },
            
            output() {
                const t = this;

                if (this.is_loading) return;
                if (!this.isCanSend) return;

                this.error = false;
                this.is_disable_send = true;
                this.is_loading = true;

                if (this.wallet.value == 2 && (this.ticker == 'BTC' && this.amount * Blockchain.tickers.values[User.currency].last >= 1000) || 
                (this.ticker == 'USD' && this.amount >= 1000)) {
                    this.createWithdrawal();
                } else {
                    this.$request.post("wallet.send", this.transaction ).then( result => {
                        t.$emit("afterTransactionSend", result );
                        t.tx = result;
                        t.is_loading = false;
                    }).catch(err => {
                        this.is_loading = false;
    
                        if (err.code == 1 && err.status == "fail" && err.message == "Insufficient funds") {
                            if (this.wallet.value == 2) {
                                this.createWithdrawal();
                            } else {
                                this.error_msg = "На кошельке недостаточно средств"
                                this.error = true
                            }
                        } else {
                            this.error_msg = err.message
                            this.error = true
                            this.is_disable_send = false;   
                        }
                    });
                }
            },
            
            changeSelectedWallet( value ) {
               if( value !== 0 && value !== 1 ) return;
               this.wallet = this.wallets[value];
               this.is_disable_send = false;
            },
            
            focusDropdown( args ) {
                let input = document.querySelector("#output-select input");
                if( input ) {
                    input.focus();
                }                
            },
            
            outputAllAvailableBalance( args ) {
                this.is_output_all = true;
                
                switch( this.ticker ) {
                    case 'BTC' : this.amount =  Math.floor10(this.available_balance, -8); break;
                    default: this.amount =  Math.floor10(this.available_balance, -8) * Blockchain.tickers.values[User.currency].last; break;
                }
            },
            
            start_and_end(str, start = 10, end = 5) {
                if (str.length > 20) {
                    return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },
            
            changeCurrencyType( ticker ) {
                
                if( ticker === this.ticker ) return;
                
                if( this.amount ) {
                    switch (ticker) {
                        case "BTC" :  this.amount =  this.amount / Blockchain.tickers.values[User.currency].last; break;
                        default:   this.amount = this.amount * Blockchain.tickers.values[User.currency].last; break;
                    }
                }
                
                this.ticker = ticker;
            },

            createWithdrawal() {
                this.$request.post("withdrawal/create", this.withdrawal ).then( result => {
                    this.$emit("afterTransactionSend", result );
                    this.wr = result;
                    this.is_loading = false;
                }).catch(err => {
                    this.is_loading = false;
                    this.error_msg = err.message
                    this.error = true
                });
                
            }
        },
        computed: {       
            
            withdrawal() {
                var amount = this.amount;
                
                if( this.ticker !== "BTC" ) {
                    amount = amount / Blockchain.tickers.values[User.currency].last;
                } 

                return {
                    amount:  this.is_output_all ? (Math.floor10(parseFloat(amount) - parseFloat(this.fee), -8)).toString() : (Math.floor10(parseFloat(amount), -8)).toString(),
                    amount_btc: this.is_output_all ? (Math.floor10(parseFloat(amount) - parseFloat(this.fee), -8)).toString() : (Math.floor10(parseFloat(amount), -8)).toString(),
                    ticker: this.ticker,
                    address : this.address,
                    priority: this.priority.value,
                    from: this.wallet.value,
                    estimatefee: this.fee,
                    sign: this.sign
                }
            },
            
            transaction() {
                var amount = this.amount;
               
                if( this.ticker !== "BTC" ) {
                    amount = amount / Blockchain.tickers.values[User.currency].last;
                }

                return {
                    address : this.address,
                    amount: Math.floor10(parseFloat(amount), -8),
                    priority: this.priority.value,
                    from: this.wallet.value,
                    estimatefee: this.fee,
                    sign: this.sign,
                    subtractfeefromamount: this.is_output_all
                }
                
            },
            
            params() {
                
                var amount = this.amount;
                
                if( this.ticker !== "BTC" ) {
                    amount = amount / Blockchain.tickers.values[User.currency].last;
                }
                
                return {
                    address : this.address,
                    amount: amount,
                    priority: this.priority.value,
                    from: this.wallet.value
                }
                
            },
            
            available_currency_balance() {
                
                if( this.ticker === "BTC" ) {
                    return (Math.floor10(this.available_balance, -8)).toString();
                }
                
                return (this.available_balance * Blockchain.tickers.values[User.currency].last).formatMoney(2," "," ");
                
            },
            
            available_balance() {
                switch(this.wallet.value) {
                    case 1: return this.user.wallet.balance;
                    case 2: return this.user.balance;
                }
            },
            
            isAmountAvailable() {
                   
                var amount = Math.floor10(this.amount, -8);
                
                if( this.ticker !== "BTC" ) {
                   amount = amount / Blockchain.tickers.values[User.currency].last;
                }
                if (!this.is_output_all) {
                    var result = parseFloat(this.available_balance) >= ( parseFloat(amount) + parseFloat(this.fee) );
                } else {
                    var result = parseFloat(amount) > parseFloat(this.fee);
                }
                
                return result;
                
            },
            
            isCanSend(result = null) {

                if( this.is_disable_send ) return false;
                if( !this.isAddressValide ) return false;
                if( !this.amount ) return false;
                if( !this.sign ) return false;
                if( !this.isAmountAvailable ) return false;
                
                return true;
                
            },
            
            fee_currency() {
                
                if( this.ticker === "BTC" ) {
                    return Math.round10(this.fee, -8);
                }
                
                return (parseFloat(this.fee) * Blockchain.tickers.values[User.currency].last).formatMoney(2," "," ");
                
            },
            
            total_amount() {
                
                if (!this.is_output_all) {
                    if( this.ticker === "BTC" ) {
                        return Math.floor10(parseFloat(this.amount) + parseFloat(this.fee), -8);
                    }
                                                    
                    return (this.amount + (this.fee * Blockchain.tickers.values[User.currency].last)).formatMoney(2," "," ");
                } else {
                    return Math.floor10(this.amount, -8);
                }
                
            },
            
            isAddressValide() {
                if( !this.address ) return false; 
                return Validator.validate( this.address, "BTC" );
            },
            
            currency() {
                return User.currency;
            },
            user() {
                return User.self
            }
        },
        components: {
            vSelect
        },
        watch: {
            
            "priority" : function() {
                if( this.amount && this.isAddressValide ) {
                    this.createrawtransaction();
                }
            },

            "address" : function() {
                if ( this.amount && this.isAddressValide ) {
                    this.createrawtransaction();
                }
            },
            
            "amount" : function( val ) {
                if( val && val > 0 ) {
                    if (Math.floor10(this.amount, -8) !== Math.floor10(this.available_balance, -8)) {
                       this.is_output_all = false; 
                    }
                    this.createrawtransaction();
                } else this.is_output_all = false;
            }
        },
        mounted() {
            if( this.$route.query.to && Validator.validate(this.$route.query.to, "BTC") ) {
                this.address = this.$route.query.to;
            }
            
            if( this.$route.query.amount ) {
                this.amount = this.$route.query.amount;
            }

            if (this.$route.query.to && Validator.validate(this.$route.query.to, "BTC") && this.$route.query.amount) {
                this.is_pay_contract = true
            }
            
        //    setTimeout(() => {
        //        this.tx = {
        //            address: "bc1quswdt5xfu3dvt5qre2kndsuztf080dggwnqcs4",
        //            amount: 0.0001,
        //            txid: "63ee1d80ebf39fee92a10537ba4deafa87c35ce00c9316196d6e3d611b816389",
        //            fee: -0.0001692
        //        };
        //    }, 1500 );

        //     setTimeout(() => {
        //        this.wr = {
        //            address: "bc1quswdt5xfu3dvt5qre2kndsuztf080dggwnqcs4",
        //            amount: 0.0001,
        //            txid: "63ee1d80ebf39fee92a10537ba4deafa87c35ce00c9316196d6e3d611b816389",
        //            fee: -0.0001692
        //        };
        //    }, 1500 );
        

        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
 