<template>
  <b-overlay
        :show="is_loading"
        blur="50"
        variant='white'
        spinner-variant="primary"
        spinner-lg
        rounded="lg"
    > 
        <transition name="zoom-fade" mode="out-in">
            <div v-if="tx">
                <div class="d-flex">
                    <Lottie 
                        :options="animationData" 
                        :height="100" 
                        :width="200" 
                    />
                </div>

                <div class="d-flex justify-content-around">
                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.from')}}</span>
                        <p class="m-0">{{$t('wallet.payment-wallet')}}</p>
                    </b-form-group>

                    <div class="icon-gray-bg mt-auto mb-auto d-flex" style="width: 18px; height: 18px; border-radius: 18px; margin-left: 12px; margin-right: 12px;">
                        <feather-icon icon="ChevronRightIcon" class="text-muted m-auto" />
                    </div>

                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.to')}}</span>
                        <p class="m-0">{{$t('wallet.main-wallet')}}</p>
                    </b-form-group>
                </div>

                <b-list-group flush class="no-hover" style="margin-top: 16px;">
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.transaction-number')}}
                        </span>
                        <span>
                            <b-link class="text-black">{{ start_and_end( tx.txid ) }}</b-link>
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.transfer-amount')}}
                        </span>
                        <span class="text-black ">
                            {{ tx.amount }} BTC
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.commission-amount')}}
                        </span>
                        <span class="text-black ">
                            {{ Math.abs(tx.fee) }} BTC
                        </span>
                    </b-list-group-item>

                </b-list-group>
            </div>

            <div v-else-if="wr">
                <div class="d-flex">
                    <Lottie 
                        :options="animationData" 
                        :height="100" 
                        :width="200" 
                    />
                </div>

                <div class="d-flex" style="flex-direction: column;">
                    <p>{{$t('wallet.request-successfully')}}</p>
                    <p>{{$t('wallet.processing-time')}}</p>
                </div>

                 <div class="d-flex justify-content-around">
                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.from')}}</span>
                        <p class="m-0">{{$t('wallet.payment-wallet')}}</p>
                    </b-form-group>

                    <div class="icon-gray-bg mt-auto mb-auto d-flex" style="width: 18px; height: 18px; border-radius: 18px; margin-left: 12px; margin-right: 12px;">
                        <feather-icon icon="ChevronRightIcon" class="text-muted m-auto" />
                    </div>

                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.to')}}</span>
                        <p class="m-0">{{$t('wallet.main-wallet')}}</p>
                    </b-form-group>
                </div>

                <b-list-group flush class="no-hover" style="margin-top: 16px;">
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.transfer-amount')}}
                        </span>
                        <span class="text-black ">
                            {{ wr.amount }} BTC
                        </span>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between pl-0 pr-0" style="padding: 6px 0px;">
                        <span class="text-muted">
                            {{$t('wallet.commission-amount')}}
                        </span>
                        <span class="text-black ">
                            {{ Math.abs(wr.fee) }} BTC
                        </span>
                    </b-list-group-item>

                </b-list-group>

                <b-row>
                    <b-col>
                        <b-button @click="modalClose" variant="gray" block size="lg" style="border-radius: 16px; margin-top: 22px;">
                            {{$t('wallet.view-history')}}
                        </b-button>
                    </b-col>
                </b-row>

            </div>

            <div v-else>
                
                <div class="d-flex justify-content-around">
                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.from')}}</span>
                        <p class="m-0">{{$t('wallet.payment-wallet')}}</p>
                    </b-form-group>
                    
                    <div class="icon-gray-bg mt-auto mb-auto d-flex" style="width: 18px; height: 18px; border-radius: 18px; margin-left: 12px; margin-right: 12px;">
                        <feather-icon icon="ChevronRightIcon" class="text-muted m-auto" />
                    </div>
                    
                    <b-form-group class="form-group-with-label" style="flex-grow: 1;">
                        <span class="text-muted" style="font-size: 12px;">{{$t('wallet.to')}}</span>
                        <p class="m-0">{{$t('wallet.main-wallet')}}</p>
                    </b-form-group>
                    
                </div>
                
                <div v-if="User.self.wallet.address" class="data-form" style="margin-top: 8px;">
                    <b-form-group>
                        <div class="d-flex justify-content-between">
                            <div style="flex-grow: 1;">
                                <span class="text-muted" style="font-size: 12px;">{{$t('wallet.transfer-amount')}}</span>
                                <b-form-input v-model="amount" placeholder="0,00" type="number" />
                            </div>
                            <div class="mt-auto mb-auto">
                                <b-dropdown
                                    :text="ticker"
                                    variant="muted"
                                    size="sm"
                                    right
                                    no-caret
                                >
                                    
                                    <template #button-content>
                                        <div class="d-flex">
                                            <span>{{ ticker }}</span>
                                            <feather-icon icon="ChevronDownIcon" style="margin: auto 2px;" />
                                        </div>
                                    </template>

                                    <!-- <b-dropdown-item @click="changeCurrencyType(User.currency)">{{ User.currency }}</b-dropdown-item> -->
                                    <b-dropdown-item @click="changeCurrencyType('BTC')">BTC</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </b-form-group>
                    
                    <div class="d-flex justify-content-between" style="font-size: 12px;">
                        <span class="text-muted">
                            {{$t('wallet.available-amount')}}: {{ available_currency_balance }} {{ ticker }}
                        </span>
                        <b-link @click="outputAllAvailableBalance">
                            {{$t('wallet.withdraw-all-funds')}}
                        </b-link>
                    </div>

                    <div class="d-flex justify-content-between" style="font-size: 12px; margin-top: 8px;">
                            <span class="text-muted">
                                {{$t('wallet.priority')}}
                            </span>
                            <b-dropdown
                                variant="muted"
                                size="sm"
                                right
                                no-caret
                                style="padding: 0"
                            >

                                <template #button-content>
                                    <div class="d-flex">
                                        <span class="text-primary mb-auto mb-auto font-weight-normal" style="font-size: 12px;">{{ $t(priority.label) }}</span>
                                        <feather-icon icon="ChevronDownIcon" class="text-primary" style="margin: auto 2px; margin-right: 0;" />
                                    </div>
                                </template>

                                <b-dropdown-item v-for="(item, index) in priorities" :key="index" :active=" item.value ===  priority.value" @click="priority = item">{{ $t(item.label) }}</b-dropdown-item>

                            </b-dropdown>
                        </div>

                    
                    <hr>

                    
                        <div v-if="fee" class="d-flex justify-content-between" style=" margin-bottom: 6px;">
                            <span>{{$t('wallet.transaction-fee')}}</span>
                            <span>{{ fee_currency }} {{ ticker }}</span>
                        </div>

                        <div v-if="fee" class="d-flex justify-content-between" style=" margin-bottom: 6px;">
                            <span>{{$t('wallet.total-amount')}}</span>
                            <span>{{ total_amount }} {{ ticker }}</span>
                        </div>

                        <b-alert :show="!isAmountAvailable && amount && fee && sign" variant="danger" class="font-weight-normal" style="padding: 14px; margin-top: 16px; font-size: 12px;">
                            {{$t('wallet.not-enough-funds')}}
                            <br> 
                            <strong>{{$t('wallet.recommend-reducing-amount')}}</strong>
                        </b-alert>
                        <b-alert :show="error" variant="danger" class="font-weight-normal" style="padding: 14px; margin-top: 16px; font-size: 12px;">
                            {{ error_msg }}
                        </b-alert>
                    
                    <b-button @click="transfer" :disabled="!isCanSend" variant="primary" block size="lg" style="border-radius: 16px;">
                        <div v-if="is_loading" class="d-flex justify-content-center">
                            <span class="mt-auto mb-auto ml-1">{{$t('wallet.btns.sending')}}</span>
                        </div>
                        <span v-else>
                            {{$t('wallet.btns.transfer')}}
                        </span>
                    </b-button>
                    
                </div>

                <div v-else style="margin-top: 8px;">
                    <p> <span>{{$t('wallet.first-generate')}}</span> <a style="color: rgb(66, 111, 246); text-transform: lowercase" @click="openBasicWallet">{{$t('wallet.main-wallet')}}</a> </p>
                </div>
                
            </div>

        </transition>
    </b-overlay>
    
</template>

<script>

    import vSelect from 'vue-select'

    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain/"

    import * as SuccessAnimeView from "@/assets/anime/success.json"

    export default {

        data() {
            return {
                User,
                is_output_all: false,

                address: null,
                ticker: "BTC",
                amount: null,
                fee: null,
                sign: null,
                is_loading: false,
                is_disable_send: false,
                
                amount: null,
                ticker: 'BTC',

               priority: { label: "wallet.medium", value: "medium" },

                priorities: [
                    { label: "wallet.low", value: "low" },
                    { label: "wallet.medium", value: "medium" },
                    { label: "wallet.high", value: "height" },
                ],

                tx: null,
                wr: null,

                animationData: { 
                    animationData: SuccessAnimeView.default,
                    loop: false
                }, 

                error_msg: "",
                error: false,
            }
        },
        methods: {
            
            openBasicWallet() {
                this.$emit('updateSelectedWalletIndex', 2);
                this.$emit('close');
            },
            
            createrawtransaction() {
                if( !this.amount ) return;                
                                
                this.$request.post( "syswal.createrawtransaction", this.params ).then( result => {
                    this.fee = result.fee;
                    this.sign = result.sign;
                                        
                });
                
            },

            transfer() {
                const t = this;
                
                if (this.is_loading) return;
                if (!this.isCanSend) return;

                this.error = false;
                this.is_loading = true;
                this.is_disable_send = true;

                if ((this.ticker == 'BTC' && this.amount * Blockchain.tickers.values[User.currency].last >= 1000) || 
                (this.ticker == 'USD' && this.amount >= 1000)) {
                    this.createWithdrawal();
                } else {
                    this.$request.post("syswal.send", this.transaction ).then( result => {
                        t.$emit("afterTransactionSend", result );
                        t.tx = result;
                        this.is_loading = false;
                    }).catch(err => {
                        this.is_loading = false;

                        if (err.code == 1 && err.status == "fail" && err.message == "Insufficient funds") {
                            this.createWithdrawal();
                        } else {
                            this.is_disable_send = false;
                            this.error_msg = err.message
                            this.error = true
                        }
                    });
                }
                
            },

            createWithdrawal() {
                this.$request.post("withdrawal/create", this.withdrawal ).then( result => {
                    this.$emit("afterTransactionSend", result );
                    this.wr = result;
                    this.is_loading = false;
                }).catch(err => {
                    this.is_loading = false;
                    this.error_msg = err.message
                    this.error = true
                });
                
            },
            
            outputAllAvailableBalance( args ) {
                this.is_output_all = true;

                switch( this.ticker ) {
                    case 'BTC' : this.amount =  Math.floor10(this.available_balance, -8); break;
                    default: this.amount =  Math.floor10(this.available_balance, -8) * Blockchain.tickers.values[User.currency].last; break;
                }
                
            },
            
            changeCurrencyType( ticker ) {
                
                if( ticker === this.ticker ) return;
                
                if( this.amount ) {
                    switch (ticker) {
                        case "BTC" :  this.amount =  this.amount / Blockchain.tickers.values[User.currency].last; break;
                        default:   this.amount = this.amount * Blockchain.tickers.values[User.currency].last; break;
                    }
                }
                
                this.ticker = ticker;
            },

            start_and_end(str, start = 10, end = 5) {
                if (str.length > 20) {
                    return str.substr(0, start) + '...' + str.substr(str.length - end , str.length);
                }
                return str;
            },

            modalClose() {
                this.$emit("close", 'transfer' );
            },

        },
        
        computed: {
            withdrawal() {
                var amount = this.amount;
                
                if( this.ticker !== "BTC" ) {
                    amount = amount / Blockchain.tickers.values[User.currency].last;
                } 

                return {
                    amount:  this.is_output_all ? (Math.floor10(parseFloat(amount) - parseFloat(this.fee), -8)).toString() : (Math.floor10(parseFloat(amount), -8)).toString(),
                    amount_btc: this.is_output_all ? (Math.floor10(parseFloat(amount) - parseFloat(this.fee), -8)).toString() : (Math.floor10(parseFloat(amount), -8)).toString(),
                    ticker: this.ticker,
                    address : User.self.wallet.address,
                    priority: this.priority.value,
                    from: 2,
                    estimatefee: this.fee,
                    sign: this.sign
                }
            },

            transaction() {
                var amount = this.amount;
                
                if( this.ticker !== "BTC" ) {
                    amount = amount / Blockchain.tickers.values[User.currency].last;
                }

                return {
                    address : User.self.wallet.address,
                    amount: Math.floor10(parseFloat(amount), -8),
                    priority: this.priority.value,
                    from: 2,
                    estimatefee: this.fee,
                    sign: this.sign,
                    subtractfeefromamount: this.is_output_all
                }
                
            },

            amount_for_send() {
                
               if( this.amount ) {
                   
                   if( this.ticker === 'BTC' ) {
                       return this.amount;
                   }
                   
                   return this.amount / Blockchain.tickers.values[User.currency].last;
                   
               } 
               
               return null;
            },
            
            available_currency_balance() {
                
                if( this.ticker === "BTC" ) {
                    return (Math.floor10(this.available_balance, -8)).toString();
                }
                
                return (this.available_balance * Blockchain.tickers.values[User.currency].last).formatMoney(2," "," ");
                
            },
            
            available_balance() {
                return User.self.balance;
            },
            
            params() {
                
                var amount = this.amount;
                
                if( this.ticker !== "BTC" ) {
                    amount = amount / Blockchain.tickers.values[User.currency].last;
                }
                
                return {
                    address : User.self.wallet.address,
                    amount: amount,
                    priority: this.priority.value,
                    from: 2
                }
                
            },

            isCanSend() {

                if( this.is_disable_send ) return false;
                if( !this.amount ) return false;
                if( !this.sign ) return false;
                if( !this.isAmountAvailable ) return false;
                
                return true;
                
            },

            isAmountAvailable() {
                   
                var amount = Math.floor10(this.amount, -8);
                
                if( this.ticker !== "BTC" ) {
                   amount = amount / Blockchain.tickers.values[User.currency].last;
                }
                if (!this.is_output_all) {
                    var result = parseFloat(this.available_balance) >= ( parseFloat(amount) + parseFloat(this.fee) );
                } else {
                    var result = parseFloat(amount) > parseFloat(this.fee);
                }
                
                return result;
                
            },

            fee_currency() {
                
                if( this.ticker === "BTC" ) {
                    return Math.round10(this.fee, -8);
                }
                
                return (parseFloat(this.fee) * Blockchain.tickers.values[User.currency].last).formatMoney(2," "," ");
                
            },

            total_amount() {
                
                if (!this.is_output_all) {
                    if( this.ticker === "BTC" ) {
                        return Math.floor10(parseFloat(this.amount) + parseFloat(this.fee), -8);
                    }
                                                    
                    return (this.amount + (this.fee * Blockchain.tickers.values[User.currency].last)).formatMoney(2," "," ");
                } else {
                    return Math.floor10(this.available_balance, -8);
                }
                
            },
            
        },
        
        components: {
            vSelect
        },
        watch: {
            "amount" : function( val ) {
                if( val && val > 0 ) {
                    if (Math.floor10(this.amount, -8) !== Math.floor10(this.available_balance, -8)) {
                       this.is_output_all = false; 
                    }
                    this.createrawtransaction();
                } else this.is_output_all = false;
            },

            "priority" : function() {
                if( this.amount ) {
                    this.createrawtransaction();
                }
            },
        },
        mounted() {
        //    setTimeout(() => {
        //        this.tx = {
        //            address: "bc1quswdt5xfu3dvt5qre2kndsuztf080dggwnqcs4",
        //            amount: 0.0001,
        //            txid: "63ee1d80ebf39fee92a10537ba4deafa87c35ce00c9316196d6e3d611b816389",
        //            fee: -0.0001692
        //        };
        //    }, 1500 );
        }

    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>